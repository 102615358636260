<template>
  <div class="" v-if="!isLoggedin && $route.params.testID == 1">
    <v-dialog width="500px" v-model="registerModal">
      <v-card>
        <v-card-text class="pt-5">
          <v-window v-model="loginwindow" :reverse="this.$store.getters.isRTL" touchless>
            <v-window-item :value="1" class="">
              <LoginComponent @changeWindow="updateWindow"
                              :GALogin="'ga_login_new_preorder'"
                              preorderLogin="true"
                              @userLoggedIn="afterLogin"
                              :showRegisterLabel="true"
                              :subtitle="$t('onBoarding.titleLogin')"></LoginComponent>
            </v-window-item>
            <v-window-item :value="2">
              <RegisterUserTest
                  @changeWindow="updateWindow"
                  :GASignup="'ga_signup_psytest'"
                  preorderLogin="true"
                  @userLoggedIn="afterLogin"
                  wizard="true"
                  :from="'PSYTEST'"
                  :user-test-id="guestId"
                  :title="$t('onBoarding.titleLogin')"
              ></RegisterUserTest>
            </v-window-item>
          </v-window>
        </v-card-text>
      </v-card>
    </v-dialog>
    <h3 class="titleCollege text-center">
      تفسیر تست با هوش مصنوعی
    </h3>
    <p class="text-center mt-3">
      شما می‌توانید با کمک دستیار هوش مصنوعی
      {{ platFormInfoLS.slug }}
      ، نتیجه تست خود را بیشتر تفسیر کنید تا از جزئیات بیشتری
      در
      مورد تفسیر خود با مطلع شوید.
    </p>
<!--    <div class="sliderArea ">-->
<!--&lt;!&ndash;      <hooper rtl :wheel-control="false" auto-play :play-speed="4000" :centerMode="true"&ndash;&gt;-->
<!--&lt;!&ndash;              pagination="no" infinite-scroll&ndash;&gt;-->
<!--&lt;!&ndash;              :itemsToShow="1"&ndash;&gt;-->
<!--&lt;!&ndash;              :style="prepareHeightForHooper()"&ndash;&gt;-->
<!--&lt;!&ndash;      >&ndash;&gt;-->
<!--&lt;!&ndash;        <slide>&ndash;&gt;-->
<!--&lt;!&ndash;          <img src="../../../assets/tests/screenshut/screen_1.png" class="sliderImage" width="100%"&ndash;&gt;-->
<!--&lt;!&ndash;               alt="">&ndash;&gt;-->
<!--&lt;!&ndash;        </slide>&ndash;&gt;-->
<!--&lt;!&ndash;        <slide>&ndash;&gt;-->
<!--&lt;!&ndash;          <img src="../../../assets/tests/screenshut/screen_2.png" width="100%" class="sliderImage"&ndash;&gt;-->
<!--&lt;!&ndash;               alt="">&ndash;&gt;-->
<!--&lt;!&ndash;        </slide>&ndash;&gt;-->
<!--&lt;!&ndash;        <slide>&ndash;&gt;-->
<!--&lt;!&ndash;          <img src="../../../assets/tests/screenshut/screen_3.png" width="100%" class="sliderImage"&ndash;&gt;-->
<!--&lt;!&ndash;               alt="">&ndash;&gt;-->
<!--&lt;!&ndash;        </slide>&ndash;&gt;-->
<!--&lt;!&ndash;      </hooper>&ndash;&gt;-->
<!--&lt;!&ndash;      <img src="../../../assets/photo_2023-10-22_12-16-38.jpg" width="100%" alt="">&ndash;&gt;-->
<!--    </div>-->
    <div class="actionCollage" @click="registerModal = true">
      ثبت‌نام
    </div>
    <br>
  </div>
</template>

<script>
// import {Hooper, Slide} from "hooper";
import 'hooper/dist/hooper.css';
import RegisterUserTest from "@/components/Auth/SignUpComponenOnboarding";
import LoginComponent from "@/components/Auth/LoginTest";
import {mapGetters} from "vuex";

export default {
  components: {
    // Hooper,
    RegisterUserTest, LoginComponent,
    // Slide
  },
  data() {
    return {
      registerModal: false,
      loginwindow: 2,
    }
  },
  computed: {
    ...mapGetters({
      'isLoggedIn': 'getIsLoggedIn',
      'guestId': 'getGuestParticipantId',
    })
  },
  methods: {
    prepareHeightForHooper() {
      // $vuetify.breakpoint.mdAndUp ? 'height: 200px' : 'height:140px'
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 'height: 200px'
      } else if (this.$vuetify.breakpoint.mdAndUp) {
        return "height: 200px"
      } else {
        return 'height: 170px'
      }
    },
    updateWindow(variable) {
      this.loginwindow = variable
      // this.loginRegisterBtn = variable == 1 ? 'login' : 'register';
    },
    afterLogin() {
      this.$emit('afterLogin')
      this.registerModal = false
      // this.$emitEvent('notify', {title: 'لطفا متن پیام خود را وارد کنید', color: 'red'})
    }
  },
  mounted() {
    this.$onEvent('openModalRegister', () => {
      this.registerModal = true
    })
    // this.$emit('openModalRegister')
  }
}
</script>

<style scoped>

@media (min-width: 200px) and (max-width: 700px) {
  .sliderArea {
    width: 90% !important;
  }
}

.titleCollege {
  font-size: 25px;
  font-weight: bolder;
  color: #39B787;
  margin-bottom: 10px;
}

.sliderArea {
  width: 500px;
  margin: 30px auto 0px auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  overflow: hidden;
}

.actionCollage {
  fill: #FFFFFF;
  color: #FFFFFF;
  background-color: #39B787;
  width: 200px;
  font-size: 13px;
  padding: 10px;
  border-radius: 10px 10px 10px 10px;
  text-align: center;
  margin: 30px auto 0px auto;
  cursor: pointer;
  /*margin-right: 18%;*/
}

</style>